import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

// sections
// import ConceptSection from "../components/concept-section";
import LogoSection from "../components/logo-section";
import ColorSection from "../components/color-section";
import TypographySection from "../components/typography-section";
import PhotographySection from "../components/photography-section";

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[`brand`, `3fo`, `ArborSF`, `internal`]} title="Brand" />

      {/* Intro */}
      <section className="text-left pt-24 md:w-5/6" id="intro">
        <div className="bg-second_color sticky z-10 flex top-0">
          <h1
            className="text-heading_color font-primary_font text-5xl inline-block tracking-tighter uppercase pt-32 md:pt-12 "
            tabIndex="0"
          >
            brand guidelines
          </h1>
        </div>
        <p className="my-4" tabIndex="0">
          Welcome to our brand guidelines, a digital tool we’ve created to help
          make it a little easier for you to maintain our brand.
        </p>
        <p className="my-4" tabIndex="0">
          Here you&#39;ll find the foundational elements that create our
          Arlequin brand identity. Consistency is key in keeping our brand
          presence strong. Consistent and repetitive usage of these elements
          will create lasting recognition and a memorable connection with our
          audience.
        </p>
      </section>

      {/* Concept */}
      {/* <ConceptSection /> */}

      {/* Logo section */}
      <LogoSection />

      {/* Color Section */}
      <ColorSection />

      {/* Typography Section */}
      <TypographySection />

      {/* Photography Section */}
      <PhotographySection />
    </Layout>
  );
}
export default IndexPage;
