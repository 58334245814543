import React from "react";

// images
import masterLogo from "../images/AWM_LOGO_primary.svg";
import logoMark from "../images/AWM_LOGO-MARK_primary.svg";
import masterLogoGreen from "../images/AWM_LOGO_Green.svg";
import masterLogoYellow from "../images/AWM_LOGO_Yellow.svg";
import masterLogoBlack from "../images/AWM_LOGO_Black.svg";
import masterLogoWhite from "../images/AWM_LOGO_White.svg";
import logoMarkGreen from "../images/AWM_LOGO-MARK_Green.svg";
import logoMarkYellow from "../images/AWM_LOGO-MARK_Yellow.svg";
import logoMarkBlack from "../images/AWM_LOGO-MARK_Black.svg";
import logoMarkWhite from "../images/AWM_LOGO-MARK_White.svg";
import logoCorrect1 from "../images/AWM_Logo-usage_correct1.svg";
import logoCorrect2 from "../images/AWM_Logo-usage_correct2.svg";
import logoCorrect3 from "../images/AWM_Logo-usage_correct3.svg";
import logoCorrect4 from "../images/AWM_Logo-usage_correct4.svg";
import logoIncorrect1 from "../images/AWM_Logo-usage_incorrect1.svg";
import logoIncorrect2 from "../images/AWM_Logo-usage_incorrect2.svg";
import logoIncorrect3 from "../images/AWM_Logo-usage_incorrect3.svg";
import logoIncorrect4 from "../images/AWM_Logo-usage_incorrect4.svg";

function LogoSection() {
  return (
    <section className="text-left w-full" id="logo">
      <div className="bg-second_color sticky top-0 z-10">
        <h1 className="heading-1" tabIndex="0">
          logo
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Our logo is the most important representation of the Arlequin brand and may be used in two formats.
      </p>
      <h2 className="heading-2" tabIndex="0">
        PRIMARY LOGOS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Our master logo contains the full Arlequin name and the logo symbol contains our &#39;A&#39; emblem.
      </p>
      <div className="grid grid-cols-2 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="primary brand logo image"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={masterLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">master logo</figcaption>
        </figure>
        <figure tabIndex="0">
          <img
            alt="primary brand logo image mark"
            className="block mx-auto w-full"
            src={logoMark}
          />
          <figcaption className="text-sm mt-1 uppercase">logo symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        MASTER lOGO
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        As often as possible, our master logo should be used in our primary green brand color over our yellow background 
        color. This may be reversed when appropriate. Black and white logos are used when color is not applicable.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="master logo - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={masterLogoGreen}
          />
          <figcaption className="text-sm mt-1 uppercase">Green logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - grayscale"
            className="block mx-auto w-full"
            src={masterLogoYellow}
          />
          <figcaption className="text-sm mt-1 uppercase">yellow logo</figcaption>
        </figure>
        <figure>
          <img
            alt="logo image placeholder"
            className="block mx-auto w-full"
            src={masterLogoBlack}
          />
          <figcaption className="text-sm mt-1 uppercase">black logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - white"
            className="block mx-auto w-full"
            src={masterLogoWhite}
          />
          <figcaption className="text-sm mt-1 uppercase">white logo</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        logo SYMBOL
      </h2>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
      There are instances where the &#39;A&#39; logo symbol may be used on its own. Careful consideration should be taken when 
      using the symbol instead of the master logo. To help decide, ask yourself: Will my audience know the &#39;A&#39; stands 
      for Arlequin? Is the written name “Arlequin Wine Merchant” in close enough proximity for reference? The symbol 
      may be used for social media profile avatars, internal branded schwag, and on-location branded collateral.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="brand mark - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoMarkGreen}
          />
          <figcaption className="text-sm uppercase mt-1">Green Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - grayscale"
            className="block mx-auto w-full"
            src={logoMarkYellow}
          />
          <figcaption className="text-sm uppercase mt-1">yellow Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - black"
            className="block mx-auto w-full"
            src={logoMarkBlack}
          />
          <figcaption className="text-sm uppercase mt-1">Black Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - white"
            className="block mx-auto w-full"
            src={logoMarkWhite}
          />
          <figcaption className="text-sm uppercase mt-1">White Symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        CORRECT LOGO USAGE
      </h2>
      <ol className="text-lg my-4 md:w-3/4 list-upperAlpha list-inside">
        <li>Our logo should be used as often as possible with our primary green and yellow brand colors.</li>
        <li>The yellow logo is used on the green background color only.</li>
        <li>The green logo symbol should be used on the yellow background.</li>
        <li>The yellow logo symbol should be used on the green background.</li>
      </ol>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="master logo - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCorrect1}
          />
          <figcaption className="text-sm mt-1 uppercase">A</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - grayscale"
            className="block mx-auto w-full"
            src={logoCorrect2}
          />
          <figcaption className="text-sm mt-1 uppercase">B</figcaption>
        </figure>
        <figure>
          <img
            alt="logo image placeholder"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCorrect3}
          />
          <figcaption className="text-sm mt-1 uppercase">C</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - white"
            className="block mx-auto w-full"
            src={logoCorrect4}
          />
          <figcaption className="text-sm mt-1 uppercase">D</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        INCORRECT LOGO USAGE
      </h2>
      <ol className="text-lg my-4 md:w-3/4 list-upperAlpha list-inside">
        <li>Do not use our logo over any color other than our primary green and yellow brand colors.</li>
        <li>Do not use our logo without the secondary text.</li>
        <li>To avoid repetition, do not use our logo and logo mark in close proximity to one another</li>
        <li>Do not change the size of the secondary text in relation to the primary text.</li>
      </ol>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <div className="relative">
            <img
              alt="master logo - color"
              className="block mx-auto w-full"
              src={logoIncorrect1}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">A</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="master logo - grayscale"
              className="block mx-auto w-full"
              src={logoIncorrect2}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">B</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="logo image placeholder"
              className="block relative mx-auto w-full"
              src={logoIncorrect3}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">C</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="master logo - white"
              className="block relative mx-auto w-full"
              src={logoIncorrect4}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">D</figcaption>
        </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/AWM_LOGO.zip`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download logo 
          pack (all logo variations and file formats).</p>
      </div>
    </section>
  );
}

export default LogoSection;