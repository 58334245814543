import React from "react";

// images
// no images

function ColorSection() {
  return (
    <section className="text-left mt-20 w-full" id="color">

      <div className="bg-second_color sticky top-0 z-10">
        <h1 className="heading-1" tabIndex="0">
          color
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Our brand color palette reflects friendly warmth and creates a seamless experience with our Arbor neighbor. 
        Arlequin shares the same color palette with Arbor, but its usage is flipped in hierarchy.
      </p>
      <h2 className="heading-2" tabIndex="0">
        PRIMARY COLORS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Green and Yellow are our primary brand colors. Green is used for the logo while Yellow is used as a background fill.  
      </p>
      <div className="grid grid-cols-2 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-primary_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">GREEN
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-4/5 md:w-2/3">
              <div>HEX</div> <div>#307865</div>
              <div>RGB</div> <div>48,120,101</div>
              <div>CMYK</div> <div>81,33,65,16</div>
              <div>PANTONE</div> <div>569 U</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-second_color w-full h-48 border border-solid border-primary_color"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">yellow
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 md:w-2/3">
              <div>HEX</div> <div>#FFd87F</div>
              <div>RGB</div> <div>255,216,127</div>
              <div>CMYK</div> <div>0,15,59,0</div>
              <div>PANTONE</div> <div>1215 U</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <h2 className="heading-2" tabIndex="0">
        SECONDaRY COLoRS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Terracotta and Cream are our secondary brand colors. Terracotta is used sparingly for pops of color to 
        attract users to a CTA or enticement amongst the primary brand colors. Cream is used to add warmth to 
        backgrounds and to provide breaks to the yellow background color where needed.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-s2 w-full h-32"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">CREAM
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div> <div className="w-1/2">#FEE2CC</div>
              <div className="w-1/2">RGB</div> <div className="w-1/2">254,226,204</div>
              <div className="w-1/2">CMYK</div> <div className="w-1/2">0,12,18,0</div>
              <div className="w-1/2">PANTONE</div> <div className="w-1/2">698 U</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s1 w-full h-32"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">TERRACOTTA
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div> <div className="w-1/2">#BD4727</div>
              <div className="w-1/2">RGB</div> <div className="w-1/2">189,71,39</div>
              <div className="w-1/2">CMYK</div> <div className="w-1/2">19,85,100,8</div>
              <div className="w-1/2">PANTONE</div> <div className="w-1/2">166 U</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        {/* <div>
          <figure>
            <div className="bg-blue-200 w-full h-32"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">cream
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div> <div className="w-1/2">#345678</div>
              <div className="w-1/2">RGB</div> <div className="w-1/2">32,8,24</div>
              <div className="w-1/2">CMYK</div> <div className="w-1/2">43,24,16,3</div>
              <div className="w-1/2">PANTONE</div> <div className="w-1/2">123</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div> */}
      </div>
      <h2 className="heading-2" tabIndex="0">
        COLoR USaGE RATiO
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        This ratio shows how much you should use each of our brand colors. Green and Yellow are used most often, 
        whereas Terracotta and Cream are used less often.
      </p>
      <div className="flex mb-4">
        <div className="bg-primary_color w-1/2 h-40 font-primary_font text-second_color uppercase text-xl relative">
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-5 ml-6">Green 50%</div>
        </div>
        <div className="bg-second_color w-3/10 h-40 font-primary_font text-primary_color uppercase text-xl border border-solid border-primary_color -mx-px relative">
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-5 ml-6">Yellow 30%</div>
        </div>
        <div className="bg-s2 w-15/100 h-40 font-primary_font text-primary_color uppercase text-xl relative">
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-5 ml-6">Cream 15%</div>
        </div>
        <div className="bg-s1 w-1/20 h-40 font-primary_font text-second_color uppercase text-base relative">
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-3 md:ml-4">Terracotta 5%</div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/AWM_Color-Specs.pdf`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download color 
        specs & usage ratio.</p>
      </div>
    </section>
  );
}

export default ColorSection;