import React from "react";

// images
// no images

function TypographySection() {
  return (
    <section className="text-left mt-20 w-full" id="typography">
      <div className="bg-second_color sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          typography
        </h1>
      </div>
      <p
        className="font-second_font font-light text-2xl my-4 md:w-5/6"
        tabIndex="0"
      >
        &#39;Tenor Sans&#39; and &#39;Brandon Grotesque&#39; are our two brand
        fonts. Tenor is used for headlines while Brandon is used for paragraph
        text. This provides style and legibility.
      </p>

      <h2 className="heading-2" tabIndex="0">
        PRIMARY FONT
      </h2>
      <figure className="leading-none">
        <p className="font-primary_font text-primary_color bg-s4 text-6xl md:text-10xl tracking-tighter uppercase pl-8 pt-8 pb-8 mt-1">
          Display headline.
        </p>
        <figcaption className="font-second_font font-light text-sm uppercase mt-2">
          Display Headline, Tenor Sans Regular, 120PT, uppercase
        </figcaption>
      </figure>
      <figure className="leading-none">
        <p className="font-primary_font text-primary_color bg-s4 text-4xl tracking-tighter uppercase pl-8 pt-8 pb-8 mt-12">
          Secondary headline.
        </p>
        <figcaption className="font-second_font font-light text-sm uppercase mt-2">
          Secondary Headlines, Brandon Grotesque Regular, 42PT, uppercase
        </figcaption>
      </figure>

      <figure className="leading-tight my-4 pt-4">
        <p className="leading-normal font-second_font bg-s4 text-lg font-light md:pr-48 p-8 my-2">
          Paragraph text, gravida mi nibh, a auctor enim tempor non. Sed non
          ultrices neque. Nunc lectus lorem, condimentum vitae sem sit amet,
          lobortis ullamcorper nisi. Nam odio lorem, varius vel lacus id,
          ullamcorper suscipit arcu. Praesent vel diam nec augue porttitor
          molestie. Nam sed justo ac est sodales hendrerit quis nec eros. Mauris
          non massa vitae nunc tincidunt egestas. Vivamus enim nulla,
          pellentesque id pharetra sed, lacinia at nulla. Maecenas nec suscipit
          libero. Nunc tempor nulla eu urna tempor, ut pharetra nulla pretium.
        </p>
        <figcaption className="font-second_font font-light text-sm uppercase">
          Paragraph Text, Brandon Grotesque Regular, 18pt, sentence case
        </figcaption>
      </figure>
      <div className="flex flex-col md:flex-row h-16 mt-16 mb-24 md:mb-0">
        <a
          href={`/downloads/AWM_Typography-Specs.pdf`}
          className="no-underline"
          download
        >
          <button className="btn" id="download-typography-specs">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download typography specs & sample.
        </p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8 mb-32 md:mb-0">
        <a
          href={`https://fonts.google.com/specimen/Tenor+Sans`}
          className="no-underline"
          rel="noopener noreferrer"
          target="_blank"
        >
          <button className="btn" id="download-tenor-sans">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download &#39;Tenor Sans&#39; font via Google Fonts for print and
          digital use.
        </p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8 mb-2 md:mb-0">
        <a
          href={`https://fonts.adobe.com/fonts/brandon-grotesque`}
          className="no-underline"
          rel="noopener noreferrer"
          target="_blank"
        >
          <button className="btn" id="download-tenor-sans">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Activate &#39;Brandon Grotesque&#39; font via Adobe Font for print and
          digital use.
        </p>
      </div>
    </section>
  );
}

export default TypographySection;
