import React from "react";

// images
import photographyHero from "../images/AWM_PHOTOGRAPHY_Hero.jpg";
import photography1 from "../images/AWM_PHOTOGRAPHY_1.jpg";
import photography2 from "../images/AWM_PHOTOGRAPHY_2.jpg";
import photography3 from "../images/AWM_PHOTOGRAPHY_3.jpg";

function PhotographySection() {
  return (
    <section className="text-left mt-20 w-full" id="photography">
      <div className="bg-second_color sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          photography
        </h1>
      </div>
      <p
        className="font-second_font font-light  text-2xl my-4 md:w-5/6"
        tabIndex="0"
      >
        Our photography should always align with our brand personality. All
        photos should be: simple &amp; curated, casual &amp; comfortable,
        approachable &amp; friendly.
      </p>

      <h2 className="heading-2 my-6" tabIndex="0">
        photography art direction
      </h2>
      <ul
        className="font-second_font font-light text-xl mt-4 mb-8 md:w-5/6"
        tabIndex="0"
      >
        <li className="mb-4">
          PHOTO SAMPLE 1<br />
          Capture details like ladder, natural woods and patio greenery to
          display the unique setting.
        </li>
        <li className="mb-4">
          PHOTO SAMPLE 2<br />
          Soft focus greenery background presents sense of place, with added
          focus and a quick-read on the product.
        </li>
        <li className="mb-4">
          PHOTO SAMPLE 3<br />
          Simpler backgrounds with very soft focus provide the best contrast.
          All shots should share the same background to avoid distraction with
          in a grid of many products.
        </li>
        <li className="mb-4">
          PHOTO SAMPLE 4<br />
          Avoid distracting tangents in back drop setup that distract the eye
          when scanning many products within a grid.
        </li>
      </ul>
      <div className="mb-4">
        <figure className="">
          <img
            alt="picture of the shelves of wine in Arlequin"
            className=""
            src={photographyHero}
          />
          <figcaption className="font-second_font font-light text-sm uppercase">
            PHOTO SAMPLE 1
          </figcaption>
        </figure>
      </div>
      <div className="grid grid-cols-3 gap-8 mb-4">
        <figure className="">
          <img
            alt="photography image placeholder"
            className="block mb-1 mx-auto w-full"
            src={photography1}
          />
          <figcaption className="font-second_font font-light text-sm uppercase">
            PHOTO SAMPLE 2
          </figcaption>
        </figure>
        <figure className="">
          <img
            alt="photography image placeholder"
            className="block mb-1 mx-auto w-full"
            src={photography2}
          />
          <figcaption className="font-second_font font-light text-sm uppercase">
            PHOTO SAMPLE 3
          </figcaption>
        </figure>
        <figure className="">
          <img
            alt="photography image placeholder"
            className="block mx-auto w-full"
            src={photography3}
          />
          <figcaption className="font-second_font font-light text-sm uppercase">
            PHOTO SAMPLE 4
          </figcaption>
        </figure>
      </div>
    </section>
  );
}

export default PhotographySection;
